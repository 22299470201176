@tailwind base;
@tailwind components;
@tailwind utilities;

.dark {
  background-color: "#14181c";
}

.dash-sidebar::-webkit-scrollbar,
.command-builder-props::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.dash-sidebar::-webkit-scrollbar-button,
.command-builder-props ::-webkit-scrollbar-button,
::-webkit-scrollbar-button {
  display: none;
}

.dash-sidebar::-webkit-scrollbar-thumb,
.command-builder-props::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: #878787;
  border-radius: 8px;
  cursor: pointer;
  border: 3px solid transparent;
  background-clip: content-box;
}

.embed-container {
}

.embed {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  position: relative;
  /* background: rgba(0, 0, 0, 0.1); */
  padding: 16px 16px 16px 10px;
  background-color: #2f3136;
  margin-left: 4px;
}

.embed-content {
  width: 100%;
}

.embed::before {
  position: absolute;
  right: 100%;
  bottom: 0px;
  top: 0px;
  width: 4px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #000000;
  content: "";
}

.embed-author {
  width: 100%;
  margin-bottom: 5px;
}

.embed-author input,
.embed-footer input,
.embed-title input {
  background: #36393f;
  outline: none;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  color: #fff;
  font-weight: 600;
  line-height: 22px;
  font-size: 12px;
  letter-spacing: 0.01em;
  margin-bottom: 5px;
  padding: 5px 10px;
}

.embed-color-picker {
  position: relative;
}

.embed-color-picker span {
  font-weight: 700;
  color: #3e3f5e;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.embed-color-picker-selected {
  width: 70px;
  height: 50px;
  background-color: #f45142;
  border-radius: 3px;
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.3);
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
}

.embed-color-picker-selected i {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}

.embed-textarea {
  width: 100%;
  font-size: 14px !important;
  background-color: #36393f !important;
  border: 1px solid #000000;
  color: #fff;
  transition: border-color 0.2s ease-in-out;
  border-radius: 3px;
  padding: 15px 70px 35px 15px;
  font-weight: 500;
  outline: none;
  box-sizing: border-box;
  min-height: 150px;
  resize: none;
  width: 100%;
  font-size: 14px !important;
  background-color: #36393f !important;
  border: 1px solid #000000;
  color: #fff;
  transition: border-color 0.2s ease-in-out;
  border-radius: 3px;
  padding: 15px 70px 35px 15px;
  font-weight: 500;
  outline: none;
  box-sizing: border-box;
  min-height: 150px;
  resize: none;
}
